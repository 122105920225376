<template>
    <div>
        <div>
            <div>
                <Breadcrumb content="首页管理"/>
            </div>
            <div class="m-bg m-pd">
                <el-form label-width="120px" v-model="form">
                    <el-row>
                        <el-col :span="11" >
                            <el-form-item label="滚动banner">
                                <el-button type="warning" @click="addImgText('banner_scroll')">添加</el-button>
                                <div class="img-view">
                                    <div v-for="(v,k) in form.banner_scroll" :key="k" >
                                        <div>
                                            <img :src="v.img" alt="" class="m-fl">
                                            <div class="m-fr">
                                                <i class="el-icon-close"
                                                   style="font-size: 20px;font-weight: bold;color: red"
                                                   @click="removeItem('banner_scroll',k)"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="m-clear-both">
                                            <div>
                                                排序:{{v.sort}}&nbsp;&nbsp;&nbsp;链接地址:{{v.url}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-form-item label="友情链接">
                                <el-button type="warning" @click="addImgText('links')">添加</el-button>
                                <div class="img-view">
                                    <div v-for="(v,k) in form.links" :key="k" >
                                        <div>
                                            <img :src="v.img" alt="" class="m-fl">
                                            <div class="m-fr">
                                                <i class="el-icon-close"
                                                   style="font-size: 20px;font-weight: bold;color: red"
                                                   @click="removeItem('links',k)"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="m-clear-both">
                                            <div>
                                                排序:{{v.sort}}&nbsp;&nbsp;&nbsp;链接地址:{{v.url}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="11" >
                            <el-form-item label="固定banner">
                                <el-button type="warning" @click="addImgText('banner_ad')">添加</el-button>
                                <div class="img-view">
                                    <div v-for="(v,k) in form.banner_ad" :key="k" >
                                        <div>
                                            <img :src="v.img" alt="" class="m-fl">
                                            <div class="m-fr">
                                                <i class="el-icon-close"
                                                   style="font-size: 20px;font-weight: bold;color: red"
                                                   @click="removeItem('banner_ad',k)"
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="m-clear-both">
                                            <div>
                                                排序:{{v.sort}}&nbsp;&nbsp;&nbsp;链接地址:{{v.url}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-form-item label="版权所有">
                                <el-input type="textarea" :rows="6" v-model="form.version"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="11" >
                            <el-form-item label="嵌套视频1">
                                <el-input v-model="form.show_video1" type="textarea" :rows="6"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-form-item label="嵌套视频2">
                                <el-input v-model="form.show_video2" type="textarea" :rows="6"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!--<el-row>
                        <el-col :span="11">
                            <el-form-item label="微博链接">
                                <el-input v-model="form.weibo"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <el-form-item label="bilibili链接">
                                <el-input v-model="form.bilibili"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>-->
                    <el-row>
                        <el-form-item>
                            <el-button type="success" @click="submit">立即保存</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
        </div>
        <div>
            <el-dialog title="添加图片" :visible.sync="dialogFormVisible">
                <AvatarUploadText
                        v-if="dialogFormVisible"
                        outImgUrl=""
                        :flag="flag"
                        imgTextVal=""
                        @uploadSuccess="revUploadForm"
                ></AvatarUploadText>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import AvatarUploadText from '@/components/admin/avatar-upload-text'
    export default {
        name: "index",
        components:{Breadcrumb,AvatarUploadText},
        data() {
            return {
                form: {
                    banner_scroll   :   [],
                    banner_ad       :   [],
                    show_video1     :   "",
                    show_video2     :   "",
                    links           :   [],
                    weibo           :   "",
                    bilibili        :   "",
                    version         :   "",
                },
                dialogFormVisible:false,
                flag:"",
            }
        },
        methods: {
            submit() {
                this.$axios.post("site/siteConfig/save",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");

                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            },
            revUploadForm(obj){

                this.dialogFormVisible = false;
                this.flag = "";

                let item = {img:obj.imgUrl,sort:obj.sort,url:obj.imgText};
                switch (obj.flag) {
                    case "banner_scroll":
                        this.form.banner_scroll.push(item);
                        break;
                    case "banner_ad":
                        this.form.banner_ad.push(item);
                        break;
                    case "links":
                        this.form.links.push(item);
                        break;
                    default:
                        return
                }
            },
            addImgText(type){
                this.flag = type;
                this.dialogFormVisible = true;
            },
            removeItem(type,k){
                console.log(type,k);
                this.$confirm("是否确认删除?").then(()=>{
                    switch (type) {
                        case "banner_scroll":
                            this.form.banner_scroll.splice(k,1);
                            break;
                        case "banner_ad":
                            this.form.banner_ad.splice(k,1);
                            break;
                        case "links":
                            this.form.links.splice(k,1);
                            break;
                        default:
                            return
                    }
                });
            },
            getInfo(){
                this.$axios.post("site/siteConfig/get",{})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.form.banner_scroll   = res.data.data.banner_scroll;
                            this.form.banner_ad       = res.data.data.banner_ad;
                            this.form.show_video1     = res.data.data.show_video1;
                            this.form.show_video2     = res.data.data.show_video2;
                            this.form.links           = res.data.data.links;
                            this.form.weibo           = res.data.data.weibo;
                            this.form.bilibili        = res.data.data.bilibili;
                            this.form.version         = res.data.data.version;

                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            }
        },
        created() {
            this.getInfo();
        }
    }
</script>

<style scoped>
    .img-view{
        padding: 10px;
        width: 90%;
        height: auto;
    }
    .img-view > div{
        height: auto;
        padding: 2px;
        border: #dcdfe6 1px solid;
        margin-bottom: 10px;
    }
    .img-view img{
        max-width: 70%;
        max-height: 100px;
    }
    .img-view i:hover{
        cursor: pointer;
    }
</style>
